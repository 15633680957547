@import "../../variables";
@import "bootstrap/scss/mixins/breakpoints";

.partner-carousel {
    background-color: $partner-carousel-bg-color;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    .carousel-title {
        text-align: center;
        margin-bottom: 2rem;
    }

    .itc-container {
        max-width: 100%;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
    }

    .common-image-component {
        width: auto;
        margin: auto;
        max-width: 100%;
    }

    .carousel-button {
        margin-top: 2rem;
    }
}

.banner-carousel {
    [data-action="Home-Show"] & {
        .swiper {
            aspect-ratio: 1/1;

            @include media-breakpoint-up(md) {
                aspect-ratio: 3/1;
            }
        }
    }

    .swiper {
        margin-bottom: 2rem;
    }

    .swiper-button-next {
        @include media-breakpoint-up(md) {
            right: 2rem;
        }
    }

    .swiper-button-prev {
        @include media-breakpoint-up(md) {
            left: 2rem;
        }
    }
}
