@import "bootstrap/scss/functions";

/////////////////////////////////////////////////////////////////////////////////////////// START Bootstrap5 variables overwrites

// start prefix for :root CSS variables
$variable-prefix: bs- !default;                 // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix: $variable-prefix !default;

// end prefix for :root CSS variables

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,                             // 4px
  2: $spacer * .5,                              // 8px
  3: $spacer * .75,                             // 12px
  4: $spacer,                                   // 16px
  5: $spacer * 1.25,                            // 20px
  6: $spacer * 1.5,                             // 24px
  7: $spacer * 2,                               // 32px
  8: $spacer * 2.5,                             // 40px
  9: $spacer * 3.75,                            // 60px
) !default;

// scss-docs-end spacer-variables-maps

// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #F1F3F4 !default;
$gray-200: #DCDDDE !default;
$gray-300: #B2B5B8 !default;
$gray-400: #727476 !default;
$gray-500: #3C3A3A !default;
$gray-600: #181814 !default;
$gray-700: #0D0D0D !default;
$gray-800: #060606 !default;
$gray-900: #000 !default;
$black: #000 !default;

// scss-docs-end gray-color-variables

// scss-docs-start color-variables
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #DC3545 !default;
$orange: #fd7e14 !default;
$yellow: #FFC107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0DCAF0 !default;
$red-100: #F8D7DA !default;
$red-200: #F1AEB5 !default;
$red-300: #EA868F !default;
$red-400: #E35D6A !default;
$red-500: $red !default;
$red-600: #B02A37 !default;
$red-700: #7c0c1b !default;
$red-800: #58151C !default;
$red-900: #2C0B0E !default;
$yellow-100: #FFF3CD !default;
$yellow-200: #FFE69C !default;
$yellow-300: #FFDA6A !default;
$yellow-400: #FFCD39 !default;
$yellow-500: $yellow !default;
$yellow-600: #CC9A06 !default;
$yellow-700: #997404 !default;
$yellow-800: #664D03 !default;
$yellow-900: #332701 !default;
$green-100: #D1E7DD !default;
$green-200: #A3CFBB !default;
$green-300: #75B798 !default;
$green-400: #479F76 !default;
$green-500: $green !default;
$green-600: #146C43 !default;
$green-700: #0F5132 !default;
$green-800: #0A3622 !default;
$green-900: #051B11 !default;
$cyan-100: #CFF4FC !default;
$cyan-200: #9EEAF9 !default;
$cyan-300: #6EDFF6 !default;
$cyan-400: #3DD5F3 !default;
$cyan-500: $cyan !default;
$cyan-600: #0AA2C0 !default;
$cyan-700: #087990 !default;
$cyan-800: #055160 !default;
$cyan-900: #032830 !default;

// scss-docs-end color-variables

// scss-docs-start theme-color-variables
$primary: #002D59 !default;
$secondary: #F5AC03 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-300 !default;
$dark: $gray-600 !default;
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
) !default;
$primary-100: #E7F1FF !default;                   // no default Bootstrap 5 Variable
$primary-200: #B8D7F8 !default;                   // no default Bootstrap 5 Variable
$primary-300: #7BB3E2 !default;                   // no default Bootstrap 5 Variable
$primary-400: #005493 !default;                   // no default Bootstrap 5 Variable
$primary-500: $primary !default;                    // no default Bootstrap 5 Variable
$primary-600: #001740 !default;                   // no default Bootstrap 5 Variable
$primary-700: #11192A !default;                   // no default Bootstrap 5 Variable
$primary-800: #00040C !default;                   // no default Bootstrap 5 Variable
$primary-900: #000102 !default;                   // no default Bootstrap 5 Variable

$secondary-100: #FFEED2 !default;                 // no default Bootstrap 5 Variable
$secondary-200: #FEE1AB !default;                 // no default Bootstrap 5 Variable
$secondary-300: #FCC95E !default;                 // no default Bootstrap 5 Variable
$secondary-400: #FCBE30 !default;                 // no default Bootstrap 5 Variable
$secondary-500: $secondary !default;                // no default Bootstrap 5 Variable
$secondary-600: #EF9A1A !default;                 // no default Bootstrap 5 Variable
$secondary-700: #B37314 !default;                 // no default Bootstrap 5 Variable
$secondary-800: #86560F !default;                 // no default Bootstrap 5 Variable
$secondary-900: #432B08 !default;                 // no default Bootstrap 5 Variable

$success-100: $green-100 !default;                  // no default Bootstrap 5 Variable
$success-200: $green-200 !default;                  // no default Bootstrap 5 Variable
$success-300: $green-300 !default;                  // no default Bootstrap 5 Variable
$success-400: $green-400 !default;                  // no default Bootstrap 5 Variable
$success-500: $green !default;                      // no default Bootstrap 5 Variable
$success-600: $green-600 !default;                  // no default Bootstrap 5 Variable
$success-700: $green-700 !default;                  // no default Bootstrap 5 Variable
$success-800: $green-800 !default;                  // no default Bootstrap 5 Variable
$success-900: $green-900 !default;                  // no default Bootstrap 5 Variable

$info-100: $cyan-100 !default;                      // no default Bootstrap 5 Variable
$info-200: $cyan-200 !default;                      // no default Bootstrap 5 Variable
$info-300: $cyan-300 !default;                      // no default Bootstrap 5 Variable
$info-400: $cyan-400 !default;                      // no default Bootstrap 5 Variable
$info-500: $cyan !default;                          // no default Bootstrap 5 Variable
$info-600: $cyan-600 !default;                      // no default Bootstrap 5 Variable
$info-700: $cyan-700 !default;                      // no default Bootstrap 5 Variable
$info-800: $cyan-800 !default;                      // no default Bootstrap 5 Variable
$info-900: $cyan-900 !default;                      // no default Bootstrap 5 Variable

$warning-100: $yellow-100 !default;                 // no default Bootstrap 5 Variable
$warning-200: $yellow-200 !default;                 // no default Bootstrap 5 Variable
$warning-300: $yellow-300 !default;                 // no default Bootstrap 5 Variable
$warning-400: $yellow-400 !default;                 // no default Bootstrap 5 Variable
$warning-500: $yellow !default;                     // no default Bootstrap 5 Variable
$warning-600: $yellow-600 !default;                 // no default Bootstrap 5 Variable
$warning-700: $yellow-700 !default;                 // no default Bootstrap 5 Variable
$warning-800: $yellow-800 !default;                 // no default Bootstrap 5 Variable
$warning-900: $yellow-900 !default;                 // no default Bootstrap 5 Variable

$danger-100: $red-100 !default;                     // no default Bootstrap 5 Variable
$danger-200: $red-200 !default;                     // no default Bootstrap 5 Variable
$danger-300: $red-300 !default;                     // no default Bootstrap 5 Variable
$danger-400: $red-400 !default;                     // no default Bootstrap 5 Variable
$danger-500: $red !default;                         // no default Bootstrap 5 Variable
$danger-600: $red-600 !default;                     // no default Bootstrap 5 Variable
$danger-700: $red-700 !default;                     // no default Bootstrap 5 Variable
$danger-800: $red-800 !default;                     // no default Bootstrap 5 Variable
$danger-900: $red-900 !default;                     // no default Bootstrap 5 Variable

$theme-colors-800: (
  "primary-800":    $primary-800,
  "secondary-800":  $secondary-800,
  "success-800":    $success-800,
  "info-800":       $info-800,
  "warning-800":    $warning-800,
  "danger-800":     $danger-800,
) !default;                                         // no default Bootstrap 5 Variable

// scss-docs-end theme-color-variables

// scss-docs-start theme-text-variables
$primary-text-emphasis: $primary-500 !default;
$secondary-text-emphasis: $secondary-800 !default;
$success-text-emphasis: $success-800 !default;
$info-text-emphasis: $info-900 !default;
$warning-text-emphasis: $warning-800 !default;
$danger-text-emphasis: $danger-800 !default;
$light-text-emphasis: $gray-500 !default;
$dark-text-emphasis: $white !default;

// scss-docs-end theme-text-variables

// scss-docs-start theme-bg-subtle-variables
$primary-bg-subtle: $primary-100 !default;
$secondary-bg-subtle: $secondary-100 !default;
$success-bg-subtle: $success-100 !default;
$info-bg-subtle: $info-100 !default;
$warning-bg-subtle: $warning-100 !default;
$danger-bg-subtle: $danger-100 !default;
$light-bg-subtle: $gray-100 !default;
$dark-bg-subtle: $gray-800 !default;

// scss-docs-end theme-bg-subtle-variables

// scss-docs-start theme-border-subtle-variables
$primary-border-subtle: $primary-200 !default;
$secondary-border-subtle: $secondary-200 !default;
$success-border-subtle: $success-200 !default;
$info-border-subtle: $info-200 !default;
$warning-border-subtle: $warning-200 !default;
$danger-border-subtle: $danger-200 !default;
$light-border-subtle: $gray-200 !default;
$dark-border-subtle: $gray-900 !default;

// scss-docs-end theme-border-subtle-variables

// start `<body>` element.
$body-color: $gray-500 !default;
$body-bg: $gray-100 !default;

// end `<body>` element.

// start anchor elements.
$link-color: $primary-400 !default;

// end anchor elements.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
    xxxl: 1920px
) !default;

// scss-docs-end grid-breakpoints

// scss-docs-start container-max-widths
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
    xxxl: 1670px
) !default;

// scss-docs-end container-max-widths

// start Grid columns
$grid-gutter-width: 1.5rem !default;

// end Grid columns

// scss-docs-start border-variables
$border-color: $gray-300 !default;
$border-width: 1px !default;
$border-radius: .25rem !default;
$border-radius-sm: $border-radius !default;
$border-radius-lg: $border-radius !default;
$border-radius-xl: $border-radius !default;
$border-radius-xxl: $border-radius !default;

// scss-docs-end border-variables

// scss-docs-start box-shadow-variables
$box-shadow: 0 .1875rem 1.25rem -0.625rem rgba($black, .15) !default;
$box-shadow-sm: 0 .125rem .25rem rgba($black, .075) !default;
$box-shadow-lg: 0 .25rem 1.25rem rgba($black, .2) !default;

// scss-docs-end box-shadow-variables

// scss-docs-start badge-variables
$badge-text-transform: uppercase !default;                              // no default Bootstrap 5 Variable
$badge-color: $white !default;
$badge-bg-color: $primary-400 !default;                                 // no default Bootstrap 5 Variable
$badge-custom-colors: (
  "sale": [$danger-500, $white, $white],
  "configurable": [$primary-400, $white, $white],
  "new": [$primary-400, $white, $secondary-400],
  "topseller": [$secondary-500, $black, $white],
  "header-quantity": [$secondary-500, $primary-400, $primary-400]
) !default;

// scss-docs-end badge-variables

// start Typography
$font-size-base: 1rem !default;                                         // Assumes the browser default, typically `16px`
$font-size-xxs: $font-size-base * .625 !default;                        // 10px - no default Bootstrap 5 Variable
$font-size-xs: $font-size-base * .75 !default;                          // 12px - no default Bootstrap 5 Variable
$font-size-sm: $font-size-base * .875 !default;                         // 14px
$font-size-lg: $font-size-base * 1.25 !default;                         // 20px
$font-family-sans-serif: roboto, sans-serif !default;
$h1-font-size: $font-size-base * 1.75 !default;
$h1-font-size-sm: $font-size-base * 1.5 !default;                       // no default Bootstrap 5 Variable
$h2-font-size: $font-size-base * 1.5 !default;
$h2-font-size-sm: $font-size-base * 1.375 !default;                     // no default Bootstrap 5 Variable
$h3-font-size: $font-size-base * 1.375 !default;
$h3-font-size-sm: $font-size-base * 1.25 !default;                      // no default Bootstrap 5 Variable
$h4-font-size: $font-size-base * 1.25 !default;
$h4-font-size-sm: $font-size-base * 1.125 !default;                     // no default Bootstrap 5 Variable
$h5-font-size: $font-size-base * 1 !default;
$h6-font-size: $font-size-base * 0.875 !default;
$headings-color: $gray-800 !default;
$headings-font-weight: 700 !default;
$text-muted: $gray-200 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-base: $font-weight-normal !default;

// end Typography

// scss-docs-start table-variables
$table-bg: $white !default;

// scss-docs-end table-variables

// scss-docs-start input-btn-variables
$input-btn-padding-x: .75rem !default;
$input-btn-border-width: var(--#{$prefix}border-width) !default;
$input-btn-padding-y-lg: 0.75rem !default;
$input-btn-font-size-sm: $font-size-base * 0.75 !default;
$input-btn-font-size-lg: $font-size-base !default;

// scss-docs-end input-btn-variables

// scss-docs-start form-input-variables
$input-bg: $white !default;
$input-padding-x: $input-btn-padding-x !default;
$input-padding-x-lg: $input-padding-x !default;
$input-font-weight: $font-weight-base !default;
$input-disabled-color: $gray-300 !default;
$input-disabled-bg: $gray-100 !default;
$input-disabled-border-color: $gray-300 !default;
$input-border-width: $input-btn-border-width !default;
$input-height-border: calc(#{$input-border-width} * 2) !default; // stylelint-disable-line function-disallowed-list

// scss-docs-end form-input-variables

// scss-docs-start form-variables
$form-check-input-width: 1.25em !default;
$form-check-input-checked-bg-color: $primary-300 !default;
$form-check-input-border-color-hover: $primary-200 !default;            // no default Bootstrap 5 Variable
$form-check-margin-bottom: 0 !default;
$form-check-btn-active-bg-color: $primary-100 !default;                 // no default Bootstrap 5 Variable
$form-check-input-radio-border-width-pseudo: 6px !default;              // no default Bootstrap 5 Variable
$form-disabled-input-color: $gray-300 !default;                         // no default Bootstrap 5 Variable
$form-floating-height: add(3rem, $input-height-border) !default;
$form-floating-padding-x: $input-padding-x !default;
$form-floating-padding-y: 0.75rem;
$form-floating-input-padding-t: 1.25rem !default;
$form-floating-input-padding-b: 0.25rem !default;
$form-floating-label-color: $gray-400 !default;                         // no default Bootstrap 5 Variable
$form-disabled-background-color: $gray-100 !default;                    // no default Bootstrap 5 Variable

// scss-docs-end form-variables

// scss-docs-start btn-variables
$btn-font-weight: 700 !default;
$btn-text-transform: uppercase !default;                                // no default Bootstrap 5 Variable
$btn-outline-primary-border-color: $border-color !default;              // no default Bootstrap 5 Variable
$btn-outline-dark-border-color: $border-color !default;                 // no default Bootstrap 5 Variable
$btn-checkbox-border-color-hover: $primary-200 !default;                // no default Bootstrap 5 Variable
$btn-form-control-font-weight: $input-font-weight !default;             // no default Bootstrap 5 Variable
$btn-form-control-min-width: 5.25rem !default;                          // no default Bootstrap 5 Variable
$btn-xmas-red-color: $red-700 !default;                                 // no default Bootstrap 5 Variable
$btn-xmas-red-accent-color: $red-800 !default;                          // no default Bootstrap 5 Variable
$btn-custom-colors: (
  "main-cta": [$secondary-400, $secondary-400],
  "gray-100": [$gray-100, $gray-100],
  "form-control": [$white, $border-color, $body-color, $white, $border-color, $body-color, $white, $border-color, $body-color],
  "checkbox": [$white, $gray-300, $gray-400, $primary-100, $btn-checkbox-border-color-hover, $gray-400, $primary-300, $primary-300, $white],
  "variant": [$white, $gray-200, $gray-800, $white, $gray-300, $gray-800, $primary-100, $primary-300, $gray-800],
  "variant-swatch": [$white, $white, $gray-800, $white, $gray-300, $gray-800, $white, $primary-300, $gray-800],
  "white": [$white, $white],
  "xmas-red": [$btn-xmas-red-color, $btn-xmas-red-color, $white, $btn-xmas-red-accent-color, $btn-xmas-red-accent-color, $white, $btn-xmas-red-accent-color, $btn-xmas-red-accent-color, $white],
  "facebook": [#1877F2, #1877F2, $white]
) !default;                                                             // no default Bootstrap 5 Variable [bg, border, color, bg:hover, border:hover, color:hover, bg:active, border:active, color:active]
$btn-close-small-width: 0.5em !default;                                 // no default Bootstrap 5 Variable
$btn-close-small-height: $btn-close-small-width !default;               // no default Bootstrap 5 Variable
$btn-share-border: $gray-200 !default;                                  // no default Bootstrap 5 Variable
$btn-share-color: $primary !default;                                    // no default Bootstrap 5 Variable
$btn-share-bg: $white !default;                                         // no default Bootstrap 5 Variable

// scss-docs-end btn-variables

// scss-docs-start badge-variables
$badge-text-transform: uppercase !default;                              // no default Bootstrap 5 Variable
$badge-custom-colors: (
  "sale": [$danger-500, $white, $white],
  "configurable": [$primary-400, $white, $white],
  "new": [$primary-400, $white, $secondary-400],
  "topseller": [$secondary-500, $black, $white],
  "header-quantity": [$secondary-500, $primary-400, $primary-400]
) !default;

// scss-docs-end badge-variables

// scss-docs-start breadcrumb-variables
$breadcrumb-font-size: $font-size-xs !default;
$breadcrumb-padding-y: .875rem !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-item-padding-x: 0.5rem !default;
$breadcrumb-margin-bottom: 0 !default;
$breadcrumb-divider-color: $gray-500 !default;
$breadcrumb-divider: "" !default;
$breadcrumb-divider-margin-top: 0.25rem !default;                       // no default Bootstrap 5 Variable
$breadcrumb-active-color: $gray-500 !default;                           // no default Bootstrap 5 Variable
$breadcrumb-item-color: $gray-800 !default;                             // no default Bootstrap 5 Variable
$breadcrumb-wrapper-gutter: $grid-gutter-width * 0.5 !default;          // no default Bootstrap 5 Variable
$breadcrumb-icon-width: 14px !default;                                  // no default Bootstrap 5 Variable
$breadcrumb-icon-height: 14px !default;                                 // no default Bootstrap 5 Variable

// scss-docs-end breadcrumb-variables

// scss-docs-start card-variables
$card-spacer-y: $spacer * 1.25 !default;
$card-spacer-x: $spacer * 1.25 !default;
$card-title-spacer-y: $spacer * 1.25 !default;
$card-cap-padding-y: $spacer * 1.25 !default;
$card-cap-padding-x: $spacer * 1.25 !default;
$card-bg: $white !default;
$card-cap-bg: transparent !default;
$card-border-width: 0 !default;
$card-box-shadow: $box-shadow !default;
$card-inner-border-radius: $border-radius !default;

// scss-docs-end card-variables

// scss-docs-start pagination-variables
$pagination-color: $primary-400 !default;
$pagination-border-width: 0 !default;
$pagination-active-color: $primary-500 !default;
$pagination-active-bg: $primary-200 !default;
$pagination-border-radius: $border-radius !default;
$pagination-focus-bg: transparent !default;

// scss-docs-end pagination-variables

// scss-docs-start modal-variables
$modal-inner-padding: $spacer * 1.25 !default;
$modal-content-bg: $white !default;
$modal-content-border-width: 0 !default;
$modal-md: 560px !default;

// scss-docs-end modal-variables

// scss-docs-start alert-variables
$alert-padding-y: .75rem !default;
$alert-padding-x: .75rem !default;

// scss-docs-end alert-variables

// scss-docs-start dropdown-variables
$dropdown-max-height: 25rem !default;                                    // no default Bootstrap 5 Variable
$dropdown-link-color: $black !default;
$dropdown-link-hover-bg: $primary-100 !default;
$dropdown-padding-y: 0 !default;
$dropdown-bg: $white !default;
$dropdown-border-width: 0 !default;
$dropdown-item-padding-y: $spacer * 0.625 !default;
$dropdown-item-padding-x: $spacer * 1.5 !default;

// scss-docs-end dropdown-variables

// scss-docs-start navbar-variables
$navbar-padding-y: 0 !default;
$navbar-light-active-color: $primary-400 !default;
$navbar-light-hover-color: $navbar-light-active-color;
$navbar-expand-font-size: $font-size-sm !default;                       // no default Bootstrap 5 Variable
$navbar-border-width: 2px !default;                                     // no default Bootstrap 5 Variable
$nav-link-color: $gray-500 !default;
$nav-link-padding-y: .5rem !default;
$nav-link-font-weight-active: 700 !default;                             // no default Bootstrap 5 Variable
$nav-link-border-color-active: $navbar-light-active-color;              // no default Bootstrap 5 Variable
$nav-link-background-color-active: $primary-100 !default;               // no default Bootstrap 5 Variable

// scss-docs-end navbar-variables

// scss-docs-start popover-variables
$popover-bg: var(--#{$prefix}body-bg) !default;
$popover-header-bg: $popover-bg !default;
$popover-header-font-size: $font-size-sm !default;
$popover-border-color: $popover-bg !default;
$popover-header-padding-y: $spacer !default;

// scss-docs-end popover-variables

/////////////////////////////////////////////////////////////////////////////////////////// END Bootstrap5 Variable overwrites

/////////////////////////////////////////////////////////////////////////////////////////// START PGG Theming

// start global
$media-rule-hover: hover !default;
$media-rule-none: none !default;
$media-rule-landscape: landscape !default;
$global-font-path: "../fonts" !default;

// end global

// start price
$price-color: $primary !default;
$price-font-weight: 700 !default;
$reduced-price-color: $price-color !default;
$strike-price-color: $danger-500 !default;
$strike-price-font-weight: 500 !default;
$strike-price-font-size: 60% !default;
$strike-price-order: 2 !default;                                        // set value <= 0 to flip the order of sales and strike price

// end price

// start nav-tabs-radio
$nav-tabs-radio-gap: 0.75rem;
$nav-tabs-radio-before-left: 0.75rem;
$nav-tabs-radio-padding-y: 0.5rem;
$nav-tabs-radio-padding-start: 2.5rem;
$nav-tabs-radio-padding-end: $nav-tabs-radio-before-left;

// end nav-tabs-radio

// start header
$header-logo-max-width: 147px !default;
$header-logo-xxl-max-width: 300px !default;
$header-logo-max-height: 50px !default;
$header-banner-color: $white !default;
$header-banner-bg-color: $indigo !default;
$header-main-z-index: 1026 !default;
$header-main-bg-color: $primary-400 !default;
$header-main-search-and-action-height: 40px !default;
$header-main-action-padding-x: 7px !default;
$header-main-action-icon-color: $white !default;
$header-main-action-icon-color-hover: $header-main-bg-color !default;
$header-main-action-link-bg-color-hover: $primary-100 !default;
$header-main-action-link-border-width: $border-width !default;
$header-main-action-link-border-radius: $border-radius !default;
$header-main-md-height: 116px !default;
$header-main-lg-height: 64px !default;
$header-main-xxl-height: 114px !default;
$header-top-bar-color: $white !default;
$header-top-bar-bg-color: $primary-400 !default;
$header-top-bar-border-color: rgb(0 0 0 / 10%) !default;
$header-top-bar-link-color-hover: $secondary-400 !default;
$header-top-bar-language-selector-hover-color: $white !default;
$header-top-bar-language-selector-hover-bg-color: $primary-400 !default;
$header-top-bar-language-selector-z-index: $header-main-z-index + 1 !default;
$header-usp-bar-color: $white !default;
$header-usp-bar-bg-color: $primary !default;
$header-search-input-height: $header-main-search-and-action-height !default;
$header-search-suggestions-z-index: 1001 !default;
$header-no-menu-bg-color: $header-main-bg-color !default;
$header-feedback-element-z-index: $header-main-z-index - 1 !default;

// end header search

// start mini cart
$minicart-bg-color: $white !default;
$minicart-border-radius: $border-radius !default;
$minicart-total-border-color: $header-main-action-icon-color !default;
$minicart-subtotal-color: $gray-800 !default;

// end mini cart

// start cart
$cart-error-message-z-index: $header-main-z-index - 2 !default;
$cart-error-message-top: 0 !default;
$cart-error-message-md-top: $header-main-md-height !default;
$cart-error-message-lg-top: $header-main-lg-height !default;
$cart-error-message-xxl-top: $header-main-xxl-height !default;

// end cart

// start drilldown to mega menu (ddtmm)
$ddtmm-font-weight-bold: 700 !default;
$ddtmm-drilldown-z-index: $header-main-z-index + 1 !default;
$ddtmm-drilldown-header-height: 70px !default;
$ddtmm-drilldown-header-bg-color: $primary-400 !default;
$ddtmm-drilldown-header-color: $white !default;
$ddtmm-drilldown-site-nav-toggle-height: $header-main-search-and-action-height !default;
$ddtmm-drilldown-site-nav-toggle-padding-x: $header-main-action-padding-x !default;
$ddtmm-drilldown-logo-width: $header-logo-max-width !default;
$ddtmm-drilldown-logo-height: 44px !default;
$ddtmm-drilldown-overlay-bg-color: $black !default;
$ddtmm-drilldown-bg-color: $white !default;
$ddtmm-drilldown-font-size: 16px !default;
$ddtmm-drilldown-li-padding-y: 12px !default;
$ddtmm-drilldown-li-padding-x: 15px !default;
$ddtmm-drilldown-li-color: $primary-500 !default;
$ddtmm-drilldown-li-bg-color-active: $primary-300 !default;
$ddtmm-mega-menu-z-index: 800 !default;
$ddtmm-mega-menu-hover-display-delay: 0.2s !default;
$ddtmm-mega-menu-height: 500px !default;
$ddtmm-mega-menu-banner-width: $ddtmm-mega-menu-height * 0.5 !default;
$ddtmm-mega-menu-bg-color: $gray-100 !default;
$ddtmm-mega-menu-banner-bg-color: $ddtmm-mega-menu-bg-color !default;
$ddtmm-mega-menu-font-size: 16px !default;
$ddtmm-mega-menu-subcategory-color: $primary-400 !default;
$ddtmm-mega-menu-li-padding: 15px !default;
$ddtmm-mega-menu-li-color: $body-color !default;
$ddtmm-mega-menu-li-color-active: $primary-500 !default;
$ddtmm-mega-menu-li-first-level-padding: 8px 0 !default;
$ddtmm-mega-menu-li-first-level-color-highlight: $secondary-400 !default;

// end drilldown to mega menu (ddtmm)

// start mobile country selector
$mobile-country-selector-padding-y: $ddtmm-drilldown-li-padding-y !default;
$mobile-country-selector-padding-x: $ddtmm-drilldown-li-padding-x !default;
$mobile-country-selector-li-color: $ddtmm-drilldown-li-color !default;

// end mobile country selector

// start footer
$footer-bg-color: $primary !default;
$footer-color: $white !default;
$footer-subscribe-section-border: rgb(0 0 0 / 20%) !default;
$footer-info-color: $primary-300 !default;
$footer-links-color: $primary-200 !default;
$footer-links-color-hover: $secondary-400 !default;
$footer-pill-font-size: $font-size-xs !default;
$footer-pill-link-color: $white !default;
$footer-pill-link-color-hover: $secondary-400 !default;
$footer-pill-link-bg-color: $primary-400 !default;
$footer-pill-border-radius: $border-radius !default;
$footer-copyright-label-color: $primary-300 !default;
$footer-usp-bar-color: $body-color !default;
$footer-usp-bar-bg-color: $white !default;
$footer-thirdparty-provider-icons-bg: $primary-100 !default;
$footer-thirdparty-provider-icons-border-radius: $border-radius !default;

// end footer

// start product detail page
$product-detail-bg-color: $white !default;
$product-detail-cta-box-bg-color: $white !default;
$product-detail-cta-box-border-radius: $border-radius !default;
$product-detail-cta-header-buffer: 20px !default;
$product-detail-col-cta-lg-top: $header-main-lg-height + $product-detail-cta-header-buffer !default;
$product-detail-col-cta-xxl-top: $header-main-xxl-height + $product-detail-cta-header-buffer !default;
$product-detail-content-accordion-header-bg-color: $product-detail-bg-color !default;
$product-detail-attributes-font-size: $font-size-xs !default;
$product-detail-attributes-text-transform: uppercase !default;
$product-detail-attributes-seperator-color: $gray-200 !default;
$product-detail-cta-box-price-font-size: $h4-font-size !default;
$product-detail-prices-add-to-cart-actions-gutter: $grid-gutter-width * 0.5 !default;
$product-detail-cta-box-brand-logo-max-height: 30px !default;
$product-detail-cta-box-brand-logo-max-width: 90px !default;

// end product detail page

// scss-docs-start refinement-variables
$refinment-header-border-color: $gray-100 !default;
$refinement-icon-color: $primary-400 !default;
$refinement-link-color: $gray-800 !default;
$refinement-link-color-active: $primary-400 !default;

// scss-docs-end refinement-variables

// scss-docs-start swatch
$swatch-width: 2.25rem !default;

// scss-docs-end swatch

// start configurator product
$configurator-product-selector-height: 2.75rem !default;
$configurator-product-selector-bullet-diameter: 20px !default;
$configurator-product-tile-border-width: 2px !default;
$configurator-product-tile-active-border-color: $primary-400 !default;
$configurator-product-list-product-name-font-weight: 700 !default;
$configurator-product-image-header-buffer: 10px !default;
$configurator-product-image-lg-top: $header-main-lg-height + $configurator-product-image-header-buffer !default;
$configurator-product-image-xxl-top: $header-main-xxl-height + $configurator-product-image-header-buffer !default;

// end configurator product

// start product tile
$product-tile-bg-color: $white !default;
$product-tile-border-radius: $border-radius !default;
$product-tile-border-width: 0 !default;
$product-tile-border-color: transparent !default;
$product-tile-border-color-hover: transparent !default;
$product-tile-name-font-size: $font-size-sm !default;
$product-tile-name-color: $gray-800 !default;
$product-tile-price-font-size: $font-size-lg !default;
$product-tile-wishlist-icon-color: $primary-500 !default;

// end product tile

// start cart page
$cart-card-summary-color: $gray-800 !default;
$cart-checkout-continue-gutter: $grid-gutter-width * 0.5 !default;
$cart-checkout-continue-bg-color: $white !default;

// end cart page

// start checkout
$checkout-color: $gray-800 !default;
$checkout-bg-color: $body-bg !default;
$checkout-steps-step-circle-height-width: 28px !default;
$checkout-steps-step-circle-border-color: $gray-400 !default;
$checkout-steps-step-circle-bg-color-active: $white !default;
$checkout-steps-step-color-active: $primary-400 !default;
$checkout-steps-step-color-checked: $primary-500 !default;
$checkout-steps-step-icon-color-checked: $white !default;
$checkout-steps-step-label-color: $gray-400 !default;
$checkout-steps-progressbar-color: $gray-400 !default;
$checkout-steps-progressbar-color-active: $primary-500 !default;

// end checkout

// start line-item, product-card
$line-item-image-width: 60px !default;
$line-item-image-height: 60px !default;
$line-item-image-margin: 1rem !default;
$line-item-name-color: $gray-800 !default;
$line-item-name-font-size: $font-size-sm !default;
$line-item-quantity-font-size: $font-size-xs !default;
$line-item-wishlist-icon-color: $gray-400 !default;
$line-item-wishlist-added-icon-color: $product-tile-wishlist-icon-color !default;

// end line-item, product-card

// start rich content
$rich-content-headings-color: $primary !default;
$rich-content-ul-bullet-color: $primary-200 !default;
$rich-content-table-row-odd-bg: $gray-100 !default;
$rich-content-greybox-bg: $gray-100 !default;
$rich-content-specialbox-bg: $primary-100 !default;
$rich-content-separatedbox-bg: $gray-100 !default;
$rich-content-specification-li-odd-bg: $gray-100 !default;
$rich-content-important-info-color: $warning !default;
$rich-content-important-info-icon-color: $white !default;
$rich-content-important-info-icon-bg: $warning !default;

// end rich content

// start swiper slider
$swiper-pagination-active-bullet-color: $primary-400 !default;
$swiper-pagination-inactive-bullet-color: $gray-300 !default;
$swiper-navigation-buttons-bg-color: $white !default;
$swiper-navigation-buttons-svg-color: $gray-400 !default;
$swiper-navigation-button-box-shadow-color: rgb(0 41 99 / 19%) !default;

// end swiper slider

// start carousels (Page Designer)
$partner-carousel-bg-color: $white !default;

// end carousels (Page Designer)

// start search view toggler
$view-toggler-active-bg-color: $primary-100 !default;
$view-toggler-icon-active-color: $gray-500 !default;
$view-toggler-icon-color: $gray-300 !default;

// end search view toggler

// start gallery PDP
$gallery-thumbnail-arrow-bg-color: $white !default;
$gallery-thumbnail-arrow-border-color: $gray-400 !default;
$gallery-thumbnail-arrow-color: $gray-500 !default;
$gallery-thumbnail-border-color-active: $primary-400 !default;
$gallery-modal-control-position: 1rem !default;
$gallery-modal-close-padding: 0.75rem !default;
$gallery-modal-thumbnail-gallery-height: 114px !default;
$gallery-modal-img-fill-max-width: 1946px !default;

// end gallery PDP

// start product availability
$availability-colors: (
  "out-of-stock": $danger-500,
  "in-stock": $success-500,
  "partially-in-stock": $secondary-500,
  "preorder": $secondary-500,
  "backorder": $primary-400,
  "configurable": $primary-400
) !default;
$product-availability-message-colored: true !default;
$product-availability-message-font-weight: 500 !default;
$product-availability-font-size: $font-size-xs !default;
$product-availability-default-color: $body-color !default;

// end product availability

// start product listing
$product-listing-filter-bg-color: $white !default;
$product-listing-filter-header-bg-color: $primary-100 !default;
$product-listing-banner-carousel-aspect-ratio: 258 / 125 !default;
$product-listing-banner-carousel-md-aspect-ratio: 966 / 241 !default;
$product-listing-range-slider-line-color: $primary-200 !default;
$product-listing-range-slider-line-bg-color: $gray-100 !default;
$product-listing-range-slider-bullet-color: $primary-400 !default;
$product-listing-range-slider-bullet-height: 16px !default;
$product-listing-range-slider-pipe-height: 4px !default;
$product-listing-grid-gutter-width: $grid-gutter-width !default;

// end product listing

// start brand page
$letter-group-bg-color: $primary-400 !default;
$letter-group-item-color: $white !default;
$letter-group-item-active-bg-color: $white !default;
$letter-group-item-active-color: $black !default;

// end brand page

/////////////////////////////////////////////////////////////////////////////////////////// END PGG Theming
